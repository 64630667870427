import { Separator } from '@radix-ui/react-separator';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { contributors } from './helpers';

const ContributorsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-0 flex flex-col justify-end items-end py-20">
      <div className="flex flex-row justify-end items-center mb-9 child:duration-500">
        <h2 className="text-[#FFFFFF] font-extrabold text-4xl lg:text-6xl mx-5">
          {t('HOME_CONTRIBUTORS_TITLE')}
        </h2>
        <Separator className="h-14 w-2 bg-main-green" />
      </div>
      <p className="text-white font-normal text-base lg:text-xl text-right mb-3 max-w-xs md:max-w-2xl duration-500 ">
        {t('HOME_CONTRIBUTORS_DESCRIPTION')}
      </p>

      <div className="z-10 mt-16 flex flex-row flex-wrap w-full min-w-[340px] items-end justify-end md:gap-x-7 xl:gap-x-14 gap-y-10 md:gap-y-12 lg:gap-y-16 xl:gap-y-20 duration-500">
        {contributors.map((item) => (
          <div
            key={item.id}
            className="flex flex-col justify-center items-center w-[113px] duration-500"
          >
            <div className="flex flex-col justify-center items-center rounded-full bg-[url('/assets/home/contributors-background.svg')] bg-center w-[82px] h-[82px] md:w-[113px] md:h-[113px] xl:w-[141px] xl:h-[141px]">
              <div className="rounded-full overflow-hidden border border-solid border-main-green h-[4.125rem] md:h-[5.75rem] xl:h-[7.125rem] w-[4.125rem] md:w-[5.75rem] xl:w-[7.125rem] relative selectDisable">
                <Image
                  quality={100}
                  alt="contributor"
                  src={item.image}
                  layout="fill"
                  objectFit="contain"
                  className={`${item.id === 8 ? 'scale-[1.1]' : ''}`}
                />
              </div>
            </div>
            <span className="text-main-green font-bold text-xs md:text-sm xl:text-base whitespace-nowrap mt-4">
              {item.name}
            </span>
            <span className="text-main-grey whitespace-nowrap text-[0.5rem] md:text-[0.625rem] xl:text-xs">
              {item.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContributorsSection;
